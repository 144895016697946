<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Lector de xml</h1>

    <XmlViewer :xml="xmlData" class="xml-div" />

    <div class="add-global-controls">
      <b-button type="is-success" class="cancel-button" @click="downloadXML()"
        >Descargar XML</b-button
      >
      <b-button type="is-danger" class="" @click="closeModal()"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import XmlViewer from "vue-xml-viewer";

// @ is an alias to /src
export default {
  name: "XMLViewer",
  props: ["xmlData", "xmlName"],
  components: {
    XmlViewer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    downloadXML() {
      var xmltext = this.xmlData;

      var filename = `${this.xmlName}.xml`;
      var pom = document.createElement("a");
      var bb = new Blob([xmltext], { type: "text/plain" });

      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", filename);

      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
        ":"
      );
      pom.draggable = true;
      pom.classList.add("dragout");

      pom.click();
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/XMLViewer.css";
</style>
